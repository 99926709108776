import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BANNERS, CLAMANT_TASK_V2, CLAMANT_TASK_V3, CLAMANT_TASKS, CONFIRM_EMAIL, DASHBOARD_TASKS, FILE_DOWNLOAD, SWITCH_ROLE } from '../core/constants/api-endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  switchRole(id: any) {
    return this.http.post(
      SWITCH_ROLE,
      {
        role_id: id,
      }
    );
  }
  
  getBannerList() {
    return this.http.get(BANNERS);
  }

  getClamantTask(days: any) {
    return this.http.get(
      CLAMANT_TASKS + days
    );
  }

  getClamantTasksV2() {
    return this.http.get(CLAMANT_TASK_V2);
  }

  getDashboardDetailsV3() {
    return this.http.get(CLAMANT_TASK_V3);
  }

  getDashboardTasks() {
    return this.http.get(DASHBOARD_TASKS);
  }

 

  confirm_email(confirmation_token:any) {
    return this.http.post(
      CONFIRM_EMAIL,
      {
        confirmation_token: confirmation_token,
      }
    );
  }

  downloadZipFile(file_id: number): Observable<any> {
    return this.http.get(
      FILE_DOWNLOAD + file_id,
      { responseType: "blob" }
    );
  }
}
